<template>
  <div v-if="initApp">
    <CustomerWidget :teleport="false" :loginicontext="loginicontext" />
  </div>
</template>
<script>
import CustomerWidget from "@/components/widgets/CustomerWidget.vue";
import { defineComponent } from "vue";
import store from "@/store";
import i18n from "@/mixins/mixins";
import stripHTML from "@/mixins/mixins";

export default defineComponent({
  name: "CustomerApp",
  mixins: [i18n, stripHTML],
  components: { CustomerWidget },
  data() {
    return {
      initApp: false,
    };
  },
  setup() {
    const myEle = document.getElementById("crentCustomerWidget");
    let loginicontext = true;
    if (myEle !== null) {
      loginicontext = myEle.dataset.loginicontext === "false" ? false : true;
    }
    return { store, loginicontext };
  },
  async mounted() {
    const myEle = document.getElementById("crentReservation");
    if (myEle == null) {
      await store.dispatch("general/initAPP").then((res) => {
        if (res) {
          // console.log("res");
        } else {
          // this.error = "ORM app not loaded, please try later";
        }
      });
      await store.dispatch("general/initORMCORE").then((res) => {
        if (res) {
          this.initORM = true;
          this.initApp = true;
        } else {
          this.error = "ORM Core not loaded, please try later";
        }
      });
    } else {
      store.watch(
        // eslint-disable-next-line
        (state, getters) => getters["general/GET_ORMLOADED"],
        (val) => {
          this.initApp = true;
        }
      );
    }
  },
});
</script>
